export const musicTableau = [
  {
    id: "LTA003D",
    artist: "Teho",
    titre: "Shifting (Deluxe Edition)",
    cover: `${process.env.PUBLIC_URL + "/img/music/LTA003D.jpg"}`,
    lecteur: (
      <iframe src="https://open.spotify.com/embed/album//7FiybKwrVgRWNvBUykdSps?utm_source=generator&theme=0" width="100%" height="80" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>    
      ),
    lien: "https://fanlink.tv/TehoShiftingDeluxeEdition",
  },
  {
    id: "LT0073",
    artist: "Teho & Romain Garcia",
    titre: "Day Off (Felix Raphael remix)",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0073.jpg"}`,
    lecteur: (
      <iframe src="https://open.spotify.com/embed/album//35oT5RHYn3dNoIdUVCHQrt?utm_source=generator&theme=0" width="100%" height="80" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>    
      ),
    lien: "https://fanlink.tv/DayOffFelixRaphaelRemix",
  },
  {
    id: "LT0072",
    artist: "Teho",
    titre: "Five - 0 (Nuage remix)",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0072.jpg"}`,
    lecteur: (
      <iframe src="https://open.spotify.com/embed/album//5SfWD0X0EFVDoLXHPJRznn?utm_source=generator&theme=0" width="100%" height="80" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>    
      ),
    lien: "https://fanlink.tv/Five0NuageRemix",
  },
  {
    id: "LT0071",
    artist: "Teho",
    titre: "Origins (Fejkà remix)",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0071.jpg"}`,
    lecteur: (
      <iframe src="https://open.spotify.com/embed/album//0sISLBRXN3RZv5TfIH6mjI?utm_source=generator&theme=0" width="100%" height="80" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>    
      ),
    lien: "https://fanlink.tv/OriginsFejkaRemix",
  },
  {
    id: "LT0070",
    artist: "Teho",
    titre: "Continuum (Teho remix)",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0070.jpg"}`,
    lecteur: (
      <iframe src="https://open.spotify.com/embed/album//6bG5034cL2LbRtDGFdeBKA?utm_source=generator&theme=0" width="100%" height="80" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>    
      ),
    lien: "https://fanlink.tv/ContinuumOxiaRemix",
  },
  {
    id: "LT0069",
    artist: "Teho",
    titre: "Continuum (Moritz Hofbauer remix)",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0069.jpg"}`,
    lecteur: (
      <iframe src="https://open.spotify.com/embed/album/0CGXqPj2TDdDJxpZU6W8tT?utm_source=generator&theme=0" width="100%" height="80" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>    
      ),
    lien: "https://fanlink.tv/ContinuumMoritzHofbauerRemix",
  },
  {
    id: "LT0068",
    artist: "Teho",
    titre: "Five-0 (Tonic Walter remix)",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0068.jpg"}`,
    lecteur: (
      <iframe src="https://open.spotify.com/embed/album/1sd0hP881yKdtcUy3ipxP2?utm_source=generator&theme=0" width="100%" height="80" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>    
      ),
    lien: "https://fanlink.tv/Five0TonicWalterRemix",
  },
  {
    id: "LT0067",
    artist: "Teho",
    titre: "Lyra (Lauren Mia remix)",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0067.jpg"}`,
    lecteur: (
      <iframe src="https://open.spotify.com/embed/album/4ZJFNERwiHGbMlwbpSl6NZ?utm_source=generator&theme=0" width="100%" height="80" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>    
      ),
    lien: "https://fanlink.tv/LyraLaurenMiaRemix",
  },
  {
    id: "LT0066",
    artist: "Teho",
    titre: "Antinomy (NIHILS remix)",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0066.jpg"}`,
    lecteur: (
      <iframe src="https://open.spotify.com/embed/album/5yvWI51I7yXhjUsOcXOkfN?utm_source=generator&theme=0" width="100%" height="80" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>    
      ),
    lien: "https://fanlink.tv/AntinomyNihilsRemix",
  },
  {
    id: "LTA003",
    artist: "Teho",
    titre: "Shifitng",
    cover: `${process.env.PUBLIC_URL + "/img/music/LTA003.jpg"}`,
    lecteur: (
      <iframe src="https://open.spotify.com/embed/album/436NZkW2SJLX9MxWHoNCmu?utm_source=generator&theme=0" width="100%" height="80" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>    
      ),
    lien: "https://fanlink.tv/TehoShifting",
  },
  {
    id: "LT0065",
    artist: "Teho",
    titre: "Rising",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0065.jpg"}`,
    lecteur: (
      <iframe src="https://open.spotify.com/embed/album/404lbl3vOGf4UX8aYauA6D?utm_source=generator&theme=0" width="100%" height="80" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>    
      ),
    lien: "https://fanlink.tv/TehoRising",
  },
  {
    id: "LT0064",
    artist: "Teho",
    titre: "Continuum",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0064.jpg"}`,
    lecteur: (
      <iframe src="https://open.spotify.com/embed/album/3jvaSyLdngxhG2yNKwNF1E?utm_source=generator&theme=0" width="100%" height="80" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>    
      ),
    lien: "https://fanlink.tv/Teho_Continuum",
  },
  {
    id: "LT0063",
    artist: "Teho",
    titre: "Origins",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0063.jpg"}`,
    lecteur: (
      <iframe src="https://open.spotify.com/embed/album/1amcG5DOmiQc2cQeOWzVeD?utm_source=generator&theme=0" width="100%" height="80" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>    
      ),
    lien: "https://fanlink.tv/Teho_Origins",
  },
  {
    id: "LT0062",
    artist: "Teho & Makoto San",
    titre: "Hanten",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0062.jpg"}`,
    lecteur: (
      <iframe src="https://open.spotify.com/embed/album/0aHGFImZfPjGHXlbUdijvX?utm_source=generator&theme=0" width="100%" height="80" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>    
      ),
    lien: "https://fanlink.tv/Teho_MakotoSan_Hanten",
  },
  {
    id: "LT0061",
    artist: "Teho & Romain Garcia",
    titre: "Day Off",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0061.jpg"}`,
    lecteur: (
      <iframe src="https://open.spotify.com/embed/album/7qnjYeLG053eq1Dr1oqyMW?utm_source=generator&theme=0" width="100%" height="80" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>    
      ),
    lien: "https://fanlink.tv/Teho_RomainGarcia_DayOff",
  },
  {
    id: "LT0060",
    artist: "Teho",
    titre: "Lyra",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0060.jpg"}`,
    lecteur: (
    <iframe src="https://open.spotify.com/embed/album/10EVZVgK0jaOVuIW1A8GSy?utm_source=generator&theme=0" width="100%" height="80" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>    
    ),
    lien: "https://fanlink.tv/Teho_Lyra",
  },
    {
    id: "LT0059",
    artist: "Minorah",
    titre: "Reveries",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0059.jpg"}`,
    lecteur: (
    <iframe src="https://open.spotify.com/embed/album/78FYlmBTVcVvAtJbLi24Y4?utm_source=generator&theme=0" width="100%" height="80" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>    
    ),
    lien: "https://fanlink.tv/MinorahReveries",
  },
  {
    id: "LT0058",
    artist: "Polygraph",
    titre: "Confidence",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0058.jpg"}`,
    lecteur: (
    <iframe src="https://open.spotify.com/embed/album/0GMwkuoL5Zh0hWZQrsueey?utm_source=generator&theme=0" width="100%" height="80" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>    
    ),
    lien: "https://fanlink.tv/PolygraphConfidence",
  },
  {
    id: "LT0057",
    artist: "Corren Cavini",
    titre: "Wadi Dana",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0057.jpg"}`,
    lecteur: (
    <iframe src="https://open.spotify.com/embed/album/45NYhmGO76k8GWRpxYSJQN?utm_source=generator&theme=0" width="100%" height="80" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>    
    ),
    lien: "https://fanlink.tv/CorrenCaviniWadiDana",
  },
  {
    id: "LT0056",
    artist: "Yodja",
    titre: "New Day",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0056.jpg"}`,
    lecteur: (
    <iframe src="https://open.spotify.com/embed/album/5M7aHZnZvvWiOvuczLGj5L?utm_source=generator&theme=0" width="100%" height="80" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>    
    ),
    lien: "https://fanlink.tv/YodjaNewDay",
  },
  {
    id: "LT0055",
    artist: "Cloud 18",
    titre: "Mosaic",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0055.jpg"}`,
    lecteur: (
    <iframe src="https://open.spotify.com/embed/album/3WKrEP38YKWtIHzSLBaSHb?utm_source=generator&theme=0" width="100%" height="80" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>    
    ),
    lien: "https://fanlink.tv/Cloud18Mosaic",
  },
  {
    id: "LT0054",
    artist: "Mashk",
    titre: "Reliance",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0054.jpg"}`,
    lecteur: (
    <iframe src="https://open.spotify.com/embed/album/00Ef1G7V0d4XwkvEk1W8Zj?utm_source=generator&theme=0" width="100%" height="80" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>    
    ),
    lien: "https://fanlink.tv/MashkReliance",
  },
  {
    id: "LT0053",
    artist: "Minorah",
    titre: "Fleur Bleue",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0053.jpg"}`,
    lecteur: (
    <iframe src="https://open.spotify.com/embed/album/2leFlotEjleAbpdDOyQUfj?utm_source=generator&theme=0" width="100%" height="80" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>    
    ),
    lien: "https://fanlink.tv/MinorahFleurBleue",
  },
  {
    id: "LTHS001",
    artist: "Teho & Gregory Terlikowski",
    titre: "Backfirewall (OST)",
    cover: `${process.env.PUBLIC_URL + "/img/music/LTHS001.jpg"}`,
    lecteur: (
    <iframe src="https://open.spotify.com/embed/album/0QJcQdc8GPPjdgPwDbQ2md?utm_source=generator&theme=0" width="100%" height="80" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>    
    ),
    lien: "https://fanlink.tv/BackfirewallOST",
  },
  {
    id: "LT0052",
    artist: "Ceas",
    titre: "Don't Know",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0052.jpg"}`,
    lecteur: (
    <iframe src="https://open.spotify.com/embed/album/4cpJ6pkamnAMqRYgklcfYD?utm_source=generator&theme=0" width="100%" height="80" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>    
    ),
    lien: "https://fanlink.tv/CeasDontKnow",
  },
  {
    id: "LTA002D",
    artist: "Teho",
    titre: "Infinity (Deluxe Edition)",
    cover: `${process.env.PUBLIC_URL + "/img/music/LTA002D.jpg"}`,
    lecteur: (
    <iframe src="https://open.spotify.com/embed/album/64Z7e0YTTtoa7dRhpQb6kv?utm_source=generator&theme=0" width="100%" height="80" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>    
    ),
    lien: "https://fanlink.tv/InfinityDeluxeEdition",
  },
  {
    id: "LT0051",
    artist: "Teho & Aalson",
    titre: "Beyond (Aparde remix)",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0051.jpg"}`,
    lecteur: (
    <iframe src="https://open.spotify.com/embed/album/1BqNZNZ4CkcwpPLeU8rWmB?utm_source=generator" width="100%" height="80" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>    
    ),
    lien: "https://fanlink.tv/BeyondApardeRemix",
  },
  {
    id: "LT0050",
    artist: "Teho",
    titre: "At Any Cost (Romain Garcia remix)",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0050.jpg"}`,
    lecteur: (
    <iframe src="https://open.spotify.com/embed/album/412MxLUxdyeNwI32652nl6?utm_source=generator" width="100%" height="80" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>    
    ),
    lien: "https://fanlink.tv/AtAnyCost_remix",
  },
  {
    id: "LT0049",
    artist: "Teho",
    titre: "Millie (Jonas Saalbach remix)",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0049.jpg"}`,
    lecteur: (
      <iframe src="https://open.spotify.com/embed/album/5DX5EMXoW6ApwP1Sldb1UF?utm_source=generator" width="100%" height="80" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
    ),
    lien: "https://fanlink.tv/Millie_Jonas_Saalbach_rmx",
  },
  {
    id: "LT0048",
    artist: "Teho & Joris Delacroix",
    titre: "Fury (pølaroit remix)",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0048.jpg"}`,
    lecteur: (
      <iframe src="https://open.spotify.com/embed/album/0KASc800NgdIUXeNWvFXYz?utm_source=generator" width="100%" height="80" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
    ),
    lien: "https://fanlink.tv/Fury_polaroit_remix",
  },
  {
    id: "LT0047",
    artist: "Teho",
    titre: "Unlocked (Einmusik remix)",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0047.jpg"}`,
    lecteur: (
      <iframe src="https://open.spotify.com/embed/album/6vzHHn8bY3ja0rwkbhZ4NB?utm_source=generator" width="100%" height="80" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
    ),
    lien: "https://fanlink.tv/Unlocked_Einmusik_remix",
  },
  {
    id: "LTA002",
    artist: "Teho",
    titre: "Infinity",
    cover: `${process.env.PUBLIC_URL + "/img/music/LTA002.jpg"}`,
    lecteur: (
      <iframe src="https://open.spotify.com/embed/album/0SERodr4s71gsU2o2RcaSK?utm_source=generator" width="100%" height="80" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
    ),
    lien: "https://fanlink.tv/Teho_Infinity",
  },
  {
    id: "LT0046",
    artist: "Teho",
    titre: "Dark Matter",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0046.jpg"}`,
    lecteur: (
      <iframe src="https://open.spotify.com/embed/album/3gy0GBkh2YXrOmD6jdgnNF?utm_source=generator&theme=0" width="100%" height="80" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
    ),
    lien: "https://fanlink.tv/Teho_DarkMatter",
  },
  {
    id: "LT0045",
    artist: "Teho & Ceas",
    titre: "Stern",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0045.jpg"}`,
    lecteur: (
      <iframe src="https://open.spotify.com/embed/album/7bOb3nmZQPxPNucOsCY2pS?utm_source=generator&theme=0" width="100%" height="80" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
    ),
    lien: "https://fanlink.tv/Teho-Ceas_Stern",
  },
  {
    id: "LT0044",
    artist: "Teho",
    titre: "At Any Cost",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0044.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/45kHe1zex3Qg2rfv5Wo6SN?utm_source=generator&theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowfullscreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/Teho_AtAnyCost",
  },
  {
    id: "LT0043",
    artist: "Teho & Joris Delacroix",
    titre: "Fury",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0043.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/3QtWWfAjUAfVaLgKYwzueb?utm_source=generator&theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowfullscreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/Teho-JorisDelacroix_Fury",
  },

  {
    id: "LT0042",
    artist: "Teho & Aalson",
    titre: "Beyond",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0042.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/3KArNyUMUdAXmnwP72PQSz?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/Teho-Aalson_Beyond",
  },
  {
    id: "LT0041",
    artist: "Teho",
    titre: "Millie",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0041.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/56TxBcQTE7VSyhLorRTuvz?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/Teho_Millie",
  },
  {
    id: "LT0040",
    artist: "Ceas",
    titre: "Ego",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0040.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/2xKlgFPXpdosmmhxfYaHed?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/Ceas_Ego",
  },
  {
    id: "LT0039",
    artist: "Mashk",
    titre: "Yadi",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0039.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/3XViTryW8mGlRkinIPAzn4?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/Mashk_Yadi",
  },
  {
    id: "LT0038",
    artist: "In-DikA",
    titre: "Nexus",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0038.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/2WbO73OZNlX7VjDNkIbrwk?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/In-Dika_Nexus",
  },
  {
    id: "LT0037",
    artist: "Minorah",
    titre: "With Me",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0037.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/6Kwv4g1iSkzSE8YZ2I3j8X?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/Minorah_WithMe",
  },
  {
    id: "LT0036",
    artist: "Ceas",
    titre: "Tears",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0036.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/2E4hIlWVPKgwzz4CklAA49?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/Ceas_Tears",
  },
  {
    id: "LT0035",
    artist: "Seth Schwarz & Teho",
    titre: "Nasaja",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0035.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/6LyoyyrIHlozgyGE6rVoXl?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/Teho-SethSchwarz_Nasaja",
  },
  {
    id: "LT0034",
    artist: "Aalson",
    titre: "Nightcall",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0034.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/2QE0EI9msz0dmqCIhjm3hH?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/Aalson_Nightcall",
  },
  {
    id: "LT0033",
    artist: "Karmaâ & Max TenRoM",
    titre: "Serengeti",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0033.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/7qilrvlzoNSUATS14svGnL?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/Karmaa-MaxTenrom_Serengeti",
  },
  {
    id: "LT0032",
    artist: "Mashk",
    titre: "Eyes Don't Lie",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0032.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/7t3vSMeml1mAs9s7VZeKCi?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/Mashk_EyesDontLie",
  },
  {
    id: "LT0031",
    artist: "Ceas",
    titre: "Bliss",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0031.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/6NE9DzSPn6xrasE0YLikf8?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/Ceas_Bliss",
  },
  {
    id: "LTA001D",
    artist: "Teho",
    titre: "Polytone (Deluxe Edition)",
    cover: `${process.env.PUBLIC_URL + "/img/music/LTA001D.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/6ccOaSBfxHsqqzpa2ztGMf?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/Teho_Polytone-DeluxeEdition",
  },
  {
    id: "LT0030",
    artist: "Teho",
    titre: "2000 Miles",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0030.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/0FrW1IyTv37LMxbYxpuzvA?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/Teho_2000Miles",
  },
  {
    id: "LT0029",
    artist: "Teho",
    titre: "GV (Clawz SG remix)",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0029.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/1MDQWoNj72NksgwoVbENTW?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/Teho_GV-ClawzSGRemix",
  },
  {
    id: "LT0028",
    artist: "Teho",
    titre: "Anything (Joachim P. remix)",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0028.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/0f1xxKz6DbqkJlmDDi23BI?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/Teho_Anything-JoachimPastorRemix",
  },
  {
    id: "LT0027",
    artist: "Teho",
    titre: "Anything",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0027.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/6m4Ri3QeAEGgqyWQS6B7AX?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/Teho_Anything-CeasRemix",
  },
  {
    id: "LT0026",
    artist: "Teho",
    titre: "Signal (Ron Flatter remix)",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0026.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/20DdDcdajk5VQxfk2kJy5r?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/Teho_Signal-RonFlatterRemix",
  },
  {
    id: "LT0025",
    artist: "Teho",
    titre: "Irani (Joris Delacroix remix)",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0025.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/3mSD8QLyj9Nl7jAC82J2c6?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/Teho_Irani-JorisDelacroixRemix",
  },
  {
    id: "LTA001",
    artist: "Teho",
    titre: "Polytone (ALBUM)",
    cover: `${process.env.PUBLIC_URL + "/img/music/LTA001.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/0LSCZqG1dAwWKeBqDvmiTh?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/Teho_Polytone",
  },
  {
    id: "LT0024",
    artist: "Teho",
    titre: "Anything (feat. Carott)",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0024.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/76Gb5YWNrKm8WDxV30TBdG?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/Teho_Anything",
  },
  {
    id: "LT0023",
    artist: "Teho",
    titre: "Yamataï",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0023.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/78R4f1dzU4xie8BOY0XGAC?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/Teho_Yamatai",
  },
  {
    id: "LT0022",
    artist: "Various Artists",
    titre: "Labo T. remixes vol1",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0022.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/2dgkv1n7VNP1TGuJIQsblm?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/LaboT_RemixesVol1",
  },
  {
    id: "LT0021",
    artist: "Aalson",
    titre: "Neutron",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0021.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/3YWTq78OeEtKNiuGAt1v3F?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/Aalson_Neutron",
  },
  {
    id: "LT0020",
    artist: "Teho. Ceas & Aalson",
    titre: "Hysteria",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0020.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/1y254oYk4koQH6MUy5ybBF?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/Teho-Ceas-Aalson_Hysteria",
  },
  {
    id: "LTC004",
    artist: "Various Artists",
    titre: "Melodic Gems vol1",
    cover: `${process.env.PUBLIC_URL + "/img/music/LTC004.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/2rDSyac3fmQVHNQhoVP994?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/MelodicGemsVol1",
  },
  {
    id: "LT0019",
    artist: "Teho",
    titre: "Eagles",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0019.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/687LI57BvGEzBP2lvCmk0D?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/Teho_Eagles",
  },
  {
    id: "LT0018",
    artist: "Ceas",
    titre: "Ombre",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0018.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/7nJUgp3R2K9osYWNxm3k4D?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/Ceas_Ombre",
  },
  {
    id: "LT0017",
    artist: "Aalson",
    titre: "Voyager",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0017.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/1jwgeKB0MH9HVwEjFW6Rrn?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/Aalson_Voyager",
  },
  {
    id: "LT0016",
    artist: "Teho",
    titre: "Because",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0016.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/06aXfnJTAWaUBCoI68HAsX?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/Teho_Because",
  },
  {
    id: "LTC003",
    artist: "Various Artists",
    titre: "Waves in the box",
    cover: `${process.env.PUBLIC_URL + "/img/music/LTC003.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/2MjhV6tqxqaUkKWQhBBf11?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/WavesInTheBox",
  },
  {
    id: "LT0015",
    artist: "Max TenRoM",
    titre: "Railway",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0015.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/6gyReNO1ca8ANEIwacz2dW?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/MaxTenRoM_Railway",
  },
  {
    id: "LT0014",
    artist: "Teho",
    titre: "Live It",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0014.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/74EgL5CnIA1SeHkthfeQZ9?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/Teho_LiveIt",
  },
  {
    id: "LT0013",
    artist: "Axom",
    titre: "Mirage",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0013.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/6PZ06uSCoCCLzeZ7sff4oC?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/Axom_Mirage",
  },
  {
    id: "LT0012",
    artist: "Ceas",
    titre: "Seahorses",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0012.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/track/11XwUr6V10G17zBg53pDbi?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/Ceas_Seahorses",
  },
  {
    id: "LT0011",
    artist: "Aalson",
    titre: "Le Chant des Cordes",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0011.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/2C0X8tVXguqV88BWZ5VK3r?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/Aalson_LeChantDesCordes",
  },
  {
    id: "LT0010R",
    artist: "Teho",
    titre: "Mandala (Üm. remix)",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0010R.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/3EWlwXDoHVhip7TvEkxYzv?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/Teho_Mandala-UmRemix",
  },
  {
    id: "LT0010",
    artist: "Teho",
    titre: "Mandala",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0010.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/5gWS2BBKEVXj97FMtmZdj6?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/Teho_Mandala",
  },
  {
    id: "LT0009",
    artist: "Anders.",
    titre: "Alta",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0009.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/42IKefySz7y2jZ2yYBxHmE?utm_source=generator&theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/Anders_Alta",
  },
  {
    id: "LT0008",
    artist: "Lumo",
    titre: "Elusion EP",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0008.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/6nuCU6vnlEp6yIQGXQWsL1?utm_source=generator&theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/Lumo_Elusion",
  },
  {
    id: "LTC002",
    artist: "Various Artists",
    titre: "Remixes Compilation 2017",
    cover: `${process.env.PUBLIC_URL + "/img/music/LTC002.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/5tjUyqMIL0KVDeUiYQRFHe?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/SummerCompilation2017Remixes",
  },
  {
    id: "LTC001",
    artist: "Various Artists",
    titre: "Summer Compilation 2017",
    cover: `${process.env.PUBLIC_URL + "/img/music/LTC001.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/0gy8lafADZYxtxvxE1WHFT?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/SummerCompilation2017",
  },
  {
    id: "LT0007",
    artist: "We Need Cracks",
    titre: "Ever CHild EP",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0007.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/4mEFjAh0YONZx7AN8bowXg?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/WeNeedCracks_EverChild",
  },
  {
    id: "LT0006",
    artist: "Max TenRoM",
    titre: "Flavour EP",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0006.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/4doCrsDQ5Rf42oinynjrkY?utm_source=generator&theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowfullscreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/MaxTenRoM_Flavour",
  },
  {
    id: "LT0005",
    artist: "Mashk",
    titre: "Dad EP",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0005.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/6ZjxT7HLs9iQuuSUhEL7ek?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/Mashk_Dad",
  },
  {
    id: "LT0004",
    artist: "In-DikA",
    titre: "13th Floor EP",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0004.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/6sf7NlbAdFYNdhO9bUOjmS?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/InDikA_13thFloor",
  },
  {
    id: "LT0003",
    artist: "Reig (FR)",
    titre: "Jeena EP",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0003.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/198nn6ekIzC23EfHwjGdwH?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://labo-t.fanlink.to/Reig_Jeena",
  },
  {
    id: "LT0002R",
    artist: "Teho",
    titre: "Feuilles d'Automne remixes",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0002R.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/3AQ6lDkBnleI7oBPNwugJG?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/Teho_FeuillesDAutomneRemixes",
  },
  {
    id: "LT0002",
    artist: "Teho",
    titre: "Feuilles d'Automne EP",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0002.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/1m0nM8dLjLO7f2EFL9nZjP?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/Teho_FeuillesDAutomne",
  },
  {
    id: "LT0001",
    artist: "Teho",
    titre: "Together",
    cover: `${process.env.PUBLIC_URL + "/img/music/LT0001.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/album/1dUxTeHpbBT2N1e6JcfEGt?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="spotify"
      ></iframe>
    ),
    lien: "https://fanlink.tv/Teho_Together",
  },
];
