import React, { useState }from "react";
import Swal from "sweetalert2";
import emailjs from "emailjs-com";

const About = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    sendFeedback("template_nn1j01v", {
      name,
      email,
      subject,
      message,
    });
  };

  const sendFeedback = (templateId, variables) => {
    window.emailjs
      .send("gmail", templateId, variables)
      .then(() => {
        setName("");
        setSubject("");
        setEmail("");
        setMessage("");
        Swal.fire({
          icon: "success",
          title: "Sent !",
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch(
        (err) =>
          (document.querySelector(".form-message").innerHTML =
            "Error, please try again")
      );
  };

  return (
    <div id="about">
      <div className="bg-about-us">
        <div className="video-info">
          <div className="info info-Teho">
            <h2>ABOUT</h2>
            <br /><br />
            <p><iframe width="560" height="315" src="https://www.youtube.com/embed/T6RUqJscBSo?si=U6BDbNGSb2dl8Yr7" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          <br /><br />
            <br />It seems obvious that <b>Teho</b>'s talent has gone through boundaries to travel around the world. This child
from the South of France saw the birth of his vocation when he was 16, caught up in Daft Punk's
Techno, and then quickly in Stephan Bodzin or James Holden's melodies.
<br />
<br /><b>Teho</b> started producing right after, following a melodic Techno movement, frantically shaking the
electronic scene. <b>NTO</b>, his countryman and this musical movement precursor, discovered in <b>Teho</b> a
rough diamond and helped him in the development of his artistic project.

<br /><br />His music was able to travel thanks to the magic of the internet. While he is still in full artistic
construction, his talent has brought him on tour across the world, in <b>Japan, Germany, Belgium or in
England</b> , where he has attracted an audience that was already supporting him.

<br /><br />His soaring harmonics, intense melodies and hypnotic rhythms finally reached the ears of the mentor 
<b> Stephan Bodzin</b>: the latter then had <b>Teho</b> sign for two EP on his label, including the immediate classic
‘Kabuki'.

<br /><br />Therefore, <b>Teho</b> joined the rank of the most popular French artists of the melodic Techno scene. Driven
by a desire of independence and integrity, with a strict and passionate vision of music, Teho launched
his own label: <b>Labo T.</b> Thus was born a sound laboratory, welcoming both emerging artists such as <b>Aalson, Romain Garcia</b> or <b>Ceas</b>, or references on the scene like <b>Einmusik, Joris Delacroix</b> or <b>Joachim
Pastor</b>.

<br /><br />Always eager to renew himself, in 2020 he presented his first album <b>'Polytone'</b>, a project aiming at
transmitting understanding, tolerance and peace values. This aspect allowed him, despite the sanitary
crisis and the shutdown of the night life, to resonate with his listeners, thanks to his atmospheres and
sound design going far beyond the club experience.

<br /><br />Hence, turning into an international dimension, <b>Teho</b> offered to his public Live performances that were
as musically exhilarating as visually impressive. This was the case when he performed for the
prestigious YouTube Channel <b>Cercle</b> from the Provencal Colorado of Rustrel, or when he presented the
hit <b>'Glacier 3000'</b>, a track that was recorded live at 3,000 meters altitude!
This performance spread around the world thanks to the mainstream press (The Times, BBC, CBS
News, France TV).

<br /><br />His second LP <b>'Infinity'</b> was released in 2022 and showed the diversity of his musical universe as well as
his infinite possibility of renewal in his way of producing music. By mixing a unique sound, built through
the power of Techno, and also thanks to the delicacy of acoustic music, he made crowds enter his
intimate cocoon brimming with energy.

<br /><br />In 2024, <b>Teho</b> have released his third album <b>"Shifting"</b> presented as an introspective journey through which <b>Teho</b> wanted to understand his deepest fears, anxieties and traumas, but above all, to heal them.
  Crossing bright phases full of hope contrasted by darker moments where fury takes over, <b>"Shifting"</b> is the reflection of a quest towards inner peace, and of a cure which would recover the traumas of an artist who does not understand not the reasons for his unhappiness when everything seems to be smiling on him. 
  Melodically impressive compositions and dance floor ready deep, euphoric trips are what makes this album the most impressive work yet from <b>Teho</b>, offering a range of tempos, tones, and themes to captivate the minds in a high-quality manner.<br /><br /><br />
              
            </p>
            <div className="staff-team">
              <div className="graphic-design">
                <h3>GRAPHIC DESIGN :</h3>
                <a
                  href="http://www.antoinedevictor.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Antoine Devictor"
                >
                  Antoine Devictor
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="for-contact">
          <div className="booking info-Teho">
            <h3>MANAGEMENT :</h3>
            <p>
            sylvain@kidding-aside.com
            </p>
          </div>
        </div>

        <div className="for-contact">
          <div className="booking info-Teho">
            <h3>BOOKING :</h3>
            <p>
            audrey.chouikh@atlasartists.com
            </p>
          </div>
        </div>

        <div className="contact">
          <h2>CONTACT US</h2>
          <form>
            <div className="contact-groupe-info">
              <input
                type="text"
                name="name"
                placeholder="Name *"
                onChange={(e) => setName(e.target.value)}
                required="required"
                value={name}
              />
              <input
                type="email"
                name="email"
                id="email"
                placeholder="E-mail *"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                required="required"
              />
              <input
                type="text"
                name="subject"
                id="subject"
                placeholder="Subject"
                onChange={(e) => setSubject(e.target.value)}
                value={subject}
              />
            </div>
            <textarea
              name="message"
              id="message"
              placeholder="Message *"
              required="required"
              onChange={(e) => setMessage(e.target.value)}
              value={message}
            ></textarea>
            <input
              type="submit"
              name="send"
              value="SEND"
              onClick={handleSubmit}
            />
            <div className="form-message"></div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default About;
